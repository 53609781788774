import React from "react"

class FastlinkApiDocumentation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { getComponent } = this.props

    const BaseLayout = getComponent("BaseLayout", true)
    return (
        <div>
          <BaseLayout/>
        </div>
    )
  }
}

export default FastlinkApiDocumentation
