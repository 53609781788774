import React from 'react'
import SwaggerUI from "swagger-ui";
import FastlinkApiDocumentation from "../components/FastlinkApiDocumentation";

const FastlinkApiDocumentationPlugin = api_key => {
  return {
    components: {
      ApiDocumentation: FastlinkApiDocumentation
    },
    rootInjects: {
      api_key: api_key
    }
  }
}

const FastlinkDisableTryItOutPlugin = api_key => {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => false
        }
      }
    }
  }
}
document.addEventListener('turbolinks:load', () => {
  let destination = document.querySelector("#fastlink_api_documentation_element");
  if (destination) {
    const api_key = "key_fastlink_uuid"
    window.ui = FastlinkSwaggerUi(document.getElementById('api-version-select').value, api_key)
    document.getElementById('api-version-select').addEventListener('change', (event) => {
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("api_version", event.target.value);
        window.location.search = searchParams.toString();
      }
      window.ui = FastlinkSwaggerUi(api_section, event.target.value, api_key)
    })
  }
})

const FastlinkSwaggerUi = (api_version, api_key) => {
  return SwaggerUI({
    url: "/fastlink_api/swagger/v" + api_version + "/swagger.yaml",
    dom_id: "#fastlink_api_documentation_element",
    deepLinking: true,
    showExtensions: true,
    showCommonExtensions: true,
    presets: [
      SwaggerUI.presets.apis
    ],
    plugins: [
      SwaggerUI.plugins.DownloadUrl,
      SwaggerUI.plugins.Topbar,
      FastlinkApiDocumentationPlugin(api_key),
      FastlinkDisableTryItOutPlugin(api_key)
    ],
    layout: "ApiDocumentation",
    onComplete: function() {
      ui.preauthorizeApiKey("API-Key via HTTP-Header", api_key);
    },
  })
}
